import galleryImg01 from '../../assets/images/JAIPUR.jpg'
import galleryImg08 from '../../assets/images/MAHAKAL.jpeg'
import galleryImg06 from '../../assets/images/MAHESHWAR2.jpg'
import galleryImg03 from '../../assets/images/MAHESWAR.jpg'
import galleryImg05 from '../../assets/images/MANDAV.jpg'
import galleryImg02 from '../../assets/images/mountAbu.jpg'
import galleryImg07 from '../../assets/images/OMKARESHWAR1.jpg'
import galleryImg04 from '../../assets/images/UDAIPUR.jpg'


const galleryImages = [
   galleryImg01,
   galleryImg02,
   galleryImg03,
   galleryImg04,
   galleryImg05,
   galleryImg06,
   galleryImg07,
   galleryImg08
]

export default galleryImages